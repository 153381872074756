
    var riot = require('riot')
    
riot.tag2('newsletter-signup', '<div if="{showform}"> <div name="registerform" class="page-form"> <div class="row"> <div class="col-xs-12"> <label for="email" class="sr-only">Email</label> <div class="input-group"> <input name="email" data-val="true" placeholder="Your email address" required onkeyup="{keycheck}" class="email form-control" type="email"> <div onclick="{register}" class="input-group-addon"><i class="fa fa-chevron-right"></i></div> </div> </div> </div> <div if="{signup_error}" style="position:static" class="alert alert-danger">{signup_error}</div> <div if="{signup_message}" style="position:static" class="alert alert-success">{signup_message}</div> </div> </div>', '', '', function(opts) {
this.duoreform = Math.round((4872987492 + new Date().getTime()) / 1000);

this.csrf_token = window.csrf;

this.on('mount', function() {
  if (!window.location.search.match(/editmode/)) {
    this.showform = true;
  }
  return this.update();
});

this.keycheck = (function(_this) {
  return function(e) {
    if (!e) {
      e = window.event;
    }
    if (e.keyCode === 13) {
      return _this.register();
    }
  };
})(this);

this.register = (function(_this) {
  return function() {
    var data, re, validemail;
    _this.signup_error = null;
    if (!_this.email.value) {
      _this.signup_error = "You have not completed all form fields";
      setTimeout((function() {
        return _this.signup_error = null;
      }), 3000);
      return;
    }
    re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    validemail = re.test(_this.email.value);
    if (!validemail) {
      _this.signup_error = "You have not entered a valid email address.";
      return setTimeout((function() {
        return _this.signup_error = null;
      }), 3000);
    } else {
      data = {
        username: _this.email.value,
        email: _this.email.value
      };
      return _this.xhr("POST", "/duocms/api/register", data, function(res) {
        var message, ref;
        if (res.status === 200) {
          message = "You should have received an email from us. Click on the link in that email to confirm your subscription.";
          _this.signup_message = message;
        } else if ((ref = res.body) != null ? ref.message : void 0) {
          _this.signup_error = res.body.message;
          setTimeout((function() {
            return _this.signup_error = null;
          }), 3000);
        } else {
          _this.signup_error = "Signup failed, please try again";
          setTimeout((function() {
            return _this.signup_error = null;
          }), 3000);
        }
        return _this.update();
      });
    }
  };
})(this);

this.xhr = (function(_this) {
  return function(method, url, data, cback) {
    var xhr;
    xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader("x-csrf-token", window.csrf);
    xhr.onreadystatechange = function() {
      var res;
      if (xhr.readyState === 4) {
        try {
          res = JSON.parse(xhr.responseText);
        } catch (error) {
          res = "";
        }
        return cback({
          status: xhr.status,
          body: res
        });
      }
    };
    return xhr.send(JSON.stringify(data));
  };
})(this);
});
    
  