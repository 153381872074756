window.riot = riot = require 'riot'
require 'riot-kit/lib/slideshow'
require "riot-kit/lib/img_loader"
require 'riot-kit/lib/form'
require 'riot-kit/lib/twitter'
require 'riot-kit/lib/modal'
require 'riot-kit/lib/form'
require 'riot-kit/lib/ecommerce'
require 'riot-kit/lib/pagination'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/booking-calendar'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
require 'riot-kit/lib/social-share'
require('riot-kit/lib/auth/forgot-password')
require 'riot-kit/lib/recapture'

require './tags/loginbar.tag'
require './tags/ecommercebar.tag'
require './tags/navigation.tag'
require './tags/mydetails.tag'
require './tags/mypassword.tag'
require './tags/signup.tag'
require './tags/tags-input.tag'
require './tags/comments_input.tag'
require './tags/comments_output.tag'
require './tags/recaptcha.tag'
require './tags/pricetag.tag'
require './libs/slideshow.coffee'

require './tags/welcomevid.tag'
require './tags/twitter.tag'
require './tags/newsletter-signup.tag'

require './tags/social-share.tag'

riot.mount('*')
document.addEventListener 'duocms:updated', -> riot.mount('slideshow,gmap')

updateCSRF = =>
  req = new XMLHttpRequest()
  req.open("get","/", true) # get home page to refresh cookie
  req.onreadystatechange = () =>
    if req.readyState == 4
      csrfcookie = document.cookie.match(/_csrf=([^ ;]+)/)
      console.log "csrf 1",csrfcookie
      if csrfcookie && csrfcookie.length > 0
        console.log csrfcookie
        csrf = csrfcookie[1]  
        console.log csrf
        fields = document.querySelectorAll('input[name="_csrf"]')
        if fields && fields.length > 0
          console.log fields
          fields.forEach (field)=>
            console.log("updated cookie",field)
            field.setAttribute("value",csrf)
  req.send()
  setTimeout (=> updateCSRF(fields)),5*30*1000 # call again in 30 mins

fields = document.querySelectorAll('input[name="_csrf"]')
if fields && fields.length 
 setTimeout (=> updateCSRF(fields)),5*30*1000

setTimeout(=>
  flashfade()
,7000)

fc = document.querySelector('.flashclose')
if fc
  fc.addEventListener 'click',(e)=>
    flashfade()

flashfade = =>
  fm = document.querySelector('.flashmessage')
  if fm
    # console.log "Gordon's alive!"
    fm.classList.add('fadeout')


qform  = document.querySelector('.questionnaire-form')
qsubmit = document.querySelector('.qform-submit')
if qform && qsubmit
  qsubmit.addEventListener 'click',=>
    qsubmit.setAttribute('disabled',true)
    setTimeout(=>
      qsubmit.removeAttribute('disabled')
    ,2000)
    required = document.querySelectorAll('.questionnaire-form .required')
    error = false
    i = 0
    while i <= required.length - 1
      req = required[i]
      req.style.backgroundColor = 'transparent'
      req.parentNode.style.backgroundColor = 'transparent'
      if (req.type != "checkbox" && req.type != "radio" && req.value == '') || (req.type == "checkbox" && !req.checked)
        if req.type == "radio" || req.type == "checkbox"
          req.parentNode.style.backgroundColor = 'rgb(255,225,225)'
        else
          req.style.backgroundColor = 'rgb(255,225,225)'
        error = true
      i++

    if error
      required[0].scrollIntoView({behavior: "smooth"})
      return false
    else
      qform.submit()
      return